<template>
  <div class="formEdit">
    <form action="" @submit.prevent="">
      <label>Numéro de la question</label>
      <input type="text" placeholder="1" v-model="question.num">
      
      <label>Question</label>
      <input type="text" placeholder="Question" v-model="question.question">
      
      <div class="select_correct" :data-correct="question.responses[0].correct">
        <input type="checkbox" @click="setActive(0)" v-model="question.responses[0].correct">
        <input type="text" placeholder="Réponse A" v-model="question.responses[0].title">
      </div>
      <div class="select_correct" :data-correct="question.responses[1].correct">
        <input type="checkbox" @click="setActive(1)" v-model="question.responses[1].correct">
        <input type="text" placeholder="Réponse B" v-model="question.responses[1].title">
      </div>
      <div class="select_correct" :data-correct="question.responses[2].correct">
        <input type="checkbox" @click="setActive(2)" v-model="question.responses[2].correct">
        <input type="text" placeholder="Réponse C" v-model="question.responses[2].title">
      </div>
      <div class="select_correct" :data-correct="question.responses[3].correct">
        <input type="checkbox" @click="setActive(3)" v-model="question.responses[3].correct">
        <input type="text" placeholder="Réponse D" v-model="question.responses[3].title">
      </div>
      <div class="bouton" @click="$emit('save', question)">Enregistrer</div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'formEdit',
  props: {
    question: Object
  },
  created() {
    if(!this.question) {
      this.question = {
        type: 'question',
        num: null,
        question: null,
        responses: [
          { title: null },
          { title: null },
          { title: null },
          { title: null },
        ]
      }
    }
  },
  methods: {
    setActive(k) {
      for(let n in this.question.responses) {
        if(this.question.responses[n].correct)
          delete(this.question.responses[n].correct)
      }
      this.$forceUpdate()
      if(this.question.responses[k].correct)
        this.question.responses[k].correct = true
    }
  }
}
</script>

<style scoped>


  .formEdit { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: #fff; padding: 20px;
    box-shadow: 0 0 1px 0 rgb(18 32 73 / 8%), 0 8px 32px 0 rgb(55 92 192 / 8%); border: 1px solid #cce2fa; width: 400px;
  }
  
  input[type=text]::placeholder { opacity: .6; }
  
  .bouton { justify-content: center; margin-top: 6px; }
  
  .select_correct { padding: 0 0 0 16px; display: flex; align-items: center; margin-bottom: 6px; }
  .select_correct input[type=checkbox] { width: 30px; height: 30px; margin-right: 16px; flex: 0 0 auto }
  .select_correct input[type=text] { margin: 0; width: 100%; }
  .select_correct[data-correct] input[type=text] { border-color: #79d634 }
</style>
