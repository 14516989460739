<template>
  <div class="home">
    <header>
      <a href="https://pleitek.com" target="_blank">Pleitek</a> 
      <span class="titre_big">Quiz</span> 
      
      <div class="right">
        <a class="" @click="downloadSave">Exporter</a>
      </div>
    </header>
    
    
    <div class="int">
      
      <div class="titre_grp">
        <h2>Questions</h2>
        <div>
          <a class="bouton campagne_create_open" @click="create">Ajouter une question</a>
          <a style="margin-left: 6px" class="bouton campagne_create_open" @click="createVideo">Ajouter une vidéo</a>
        </div>
      </div>

      <div class="campagnes_list">

        <div class="campagne" v-for="(n, k) in questions" :key="k" :data-type="n.type" :data-live="active_k === k">
          <div class="num">{{ n.num }}</div>
          <div class="titre_bloc" @click="edit(n, k)">
            <div class="titre" v-if="n.question">{{ n.question }}</div>
            <div class="titre" v-if="n.name">{{ n.name }}</div>
            <div class="bottom">
              <div class="badge" v-for="(r, k2) in n.responses" :key="k2" :class="{ green: r.correct }">{{ r.title }}</div>
            </div>

          </div>
          <div class="right">

            <div class="badge red" v-if="active_k === k">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
              En live
            </div>
            
            <div class="up" @click="up(k)">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M17,14L12,9L7,14H17Z" /></svg>
            </div>
            <div class="up down" @click="down(k)">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M17,14L12,9L7,14H17Z" /></svg>
            </div>




            <div href="/campagne/edit/ueFPj6M7ZaPCUyrfCtuipVe1PydgtAHfrznS" class="small_btn" @click="setActive(n, k)">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M1,10V12A9,9 0 0,1 10,21H12C12,14.92 7.07,10 1,10M1,14V16A5,5 0 0,1 6,21H8A7,7 0 0,0 1,14M1,18V21H4A3,3 0 0,0 1,18M21,3H3C1.89,3 1,3.89 1,5V8H3V5H21V19H14V21H21A2,2 0 0,0 23,19V5C23,3.89 22.1,3 21,3Z" /></svg>
            </div>
          </div>
        </div>
      </div>

      </div>

    <div class="frame" v-if="preview">
      <div class="close" @click="preview = false" >x</div>
      <iframe src="https://quiz-aix-front.pages.dev/player" frameborder="0"></iframe>
    </div>
    
    <div class="setEtape">
      <div class="e" :class="{ active: etape === 4 }" @click="setEtape(4)">Boîte</div>
      <div class="e" :class="{ active: etape === 3 }" @click="setEtape(3)">100€</div>
      <div class="e" :class="{ active: etape === 2 }" @click="setEtape(2)">50€</div>
      <div class="e" :class="{ active: etape === 1 }" @click="setEtape(1)">25€</div>
      <div class="e" :class="{ active: etape === 0 }" @click="setEtape(0)"></div>
    </div>
    
    <div class="setEtape steps">
      <div class="e" @click="videoForce('15s')">15</div>
      <div class="e" @click="videoForce('base')">Base</div>
      <div class="e boite" @click="videoForce('boite')">Boite</div>
      <div class="e win" @click="videoForce('win')">Win</div>
      <div class="e loose" @click="videoForce('loose')">Loose</div>
    </div>
    
    <formEdit v-if="edit_etat" :question="edit_question" @save="save"></formEdit>
    <formEditVideo v-if="editVideo_etat" :video="editVideo_data" @save="saveVideo"></formEditVideo>

  </div>
</template>

<script>
// @ is an alias to /src

import formEdit from '@/components/formEdit'
import formEditVideo from '@/components/formEditVideo'

export default {
  name: 'Home',
  components: {
    formEdit,
    formEditVideo
  },
  data() {
    return {
      
      active: null,      
      active_k: null,
      
      edit_question_k: null,
      edit_question: null,
      edit_etat: false,
      edit_create: false,
      
      editVideo_data: null,
      editVideo_etat: false,
      
      questions: [],
      
      preview: true,
      
      etape: 0,
      
      step: null
    }
  },
  created() {
    if(localStorage.getItem('questions')) {
      this.questions = JSON.parse(localStorage.getItem('questions'))
      this.$socket.client.emit('initVideos', this.questions)
    }
  },
  methods: {
    setActive(question, k) {
      this.active_k = k
      this.active = question
      this.$socket.client.emit('questionShow', question)
    },
    edit(data, k) {
      if(data.type === 'question') {
        this.edit_create = false
        this.edit_question_k = k
        this.edit_question = data
        this.edit_etat = true
      }
      else {
        this.editVideo_etat = true
        this.editVideo_data = data
        this.edit_question_k = k
        this.edit_create = false
      }
    },
    create() {
      this.edit_create = true
      this.edit_etat = true
    },
    createVideo() {
      this.edit_create = true
      this.editVideo_etat = true
    },
    save(question) {
      console.log(question)
      if(this.edit_create)
          this.questions.push(question)
      else
        this.questions[this.edit_question_k] = this.edit_question
      
      this.$forceUpdate()
      this.edit_etat = false
      
      this.store()
    },
    saveVideo(video) {
      if(this.edit_create)
        this.questions.push(video)
      else
        this.questions[this.edit_question_k] = this.editVideo_data

      this.$forceUpdate()
      this.editVideo_etat = false
      this.store()
      this.$socket.client.emit('initVideos', this.questions)
    },
    store() {
      localStorage.setItem('questions', JSON.stringify(this.questions))
    },
    setEtape(etape) {
      this.etape = etape
      this.$socket.client.emit('setEtape', this.etape)
    },
    videoForce(video) {
      this.$socket.client.emit('questionShow', {
        type: 'video',
        name: video + '.mp4'
      })
    },
    
    
    up(i) {
      this.questions = this.array_move(this.questions, i, i - 1)
      this.$forceUpdate()
      this.store()
    },
    down(i) {
      if(this.questions.length > i + 1) {
        this.questions = this.array_move(this.questions, i, i + 1)
        this.$forceUpdate()
        this.store()
      }
    },
    array_move(arr, old_index, new_index) {
      console.log(arr, old_index, new_index)
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing purposes
    },
    downloadSave() {
      this.downloadObjectAsJson(localStorage.getItem('questions'), 'Questions')
    },
    downloadObjectAsJson(exportObj, exportName){
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(exportObj);
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }
  }
}
</script>


<style scoped>
.campagnes_list .campagne{display: flex; height: 100px; align-items: center; justify-content: space-between; margin-bottom: 10px; position: relative;
  /* box-shadow: 0 0 1px 0 rgba(18,32,73,.08), 0 8px 32px 0 rgba(55,92,192,.16);*/ border-radius: 3px; border: 1px solid #cce2fa; background: #fff;}
.campagnes_list .campagne .titre_bloc{display: flex; flex-direction: column; padding-left: 32px; cursor: pointer; min-width: 70%; max-width: 70%; }
.campagnes_list .campagne .titre_bloc .titre{color: #000052; font-weight: 900; font-size: 19px; line-height: 20px; margin-bottom: 8px; max-width: 80%}
.campagnes_list .campagne .right{display: flex; align-items: center; padding-right: 20px}
.campagnes_list .campagne .right .badge{padding: 6px 12px; margin-right: 16px;}
.campagnes_list .campagne .right .small_btn{margin-left: 20px}
.campagnes_list .campagne  .num { position: absolute; top: 0; bottom: 0; left: -50px; display: flex; align-items: center; font-size: 48px; font-weight: 900; color: #9ba7c7 }
.campagnes_list .campagne[data-type=video] { border-color: rgba(239,65,65,.5); background: rgba(239,65,65,.05) }
.campagnes_list .campagne[data-live] { border-color: #ff8f8f; background: #ff8f8f }
.campagnes_list .campagne[data-live] { border-left: 16px solid #ef4141; }
.campagnes_list .campagne[data-live] .titre_bloc { padding-left: 16px; }
.campagnes_list .campagne[data-live] .num { left: -65px; }
.campagnes_list .campagne[data-live] .up svg { fill: #fff }

.up { height: 24px; cursor: pointer }
.up svg { fill: #b6c5df }
.down { transform: rotate(180deg); margin-left: 4px; }

.frame { position: fixed; bottom: 16px; left: 16px; width: 1920px; height: 1080px; transform: scale(.18); transform-origin: 0 bottom }
.frame iframe { width: 100%; height: 100%; }
.frame .close { position: absolute; top: 6px; right: 60px; color: #fff; z-index: 2; font-size: 108px; cursor: pointer}

.setEtape { position: fixed; top: 100px; right: 16px; }
.setEtape .e { width: 60px; height: 60px; display: flex; align-items: center; justify-content: center; background: #7a95e1; color: #fff; font-weight: 600; border-radius: 50%;
  margin-bottom: 6px; box-shadow: 0 4px 8px 0 rgb(18 74 138 / 24%); border: 1px solid #eee; transition: transform .4s cubic-bezier(.31,3.4,.29,-.15); cursor: pointer;
}
.setEtape .e:hover{ transform: scale(1.05) }

.setEtape .e.active { background: #8422b0; }
.setEtape .e.boite { background: #444 }
.setEtape .e.win { background: #79d634 }
.setEtape .e.loose { background: #ef4141 }

.steps { top: inherit; bottom: 16px }

</style>