<template>
  <div class="player">
    
    <div class="question_bloc" v-if="question">
      
      <div class="int">
        
      <div class="question_nb">Question {{ question.num }}</div>
      
      <div class="question">{{ question.question }}</div>
      <div class="reponses">
        <div class="reponse" v-if="question.responses[0].title">A - {{ question.responses[0].title }}</div>
        <div class="reponse" v-if="question.responses[1].title">B -  {{ question.responses[1].title }}</div>
        <div class="reponse" v-if="question.responses[2].title">C -  {{ question.responses[2].title }}</div>
        <div class="reponse" v-if="question.responses[3].title">D -  {{ question.responses[3].title }}</div>
<!--        <div class="reponse">C - que tu misasses</div>-->
      </div>
      </div>
    </div>


    <video class="video" v-for="(n, k) in videos" :key="k" :class="{ hide: n.name !== video }" :id="n.name" :src="'/videos/' + n.name" loop></video>

    <img v-if="etape" class="etape" :src="'/etape/' + etape + '.png'" alt="">
    
    
  </div>
</template>

<script>

import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'Player',
  data() {
    return {
      question: null,
      video: null,
      videos: null,
      
      etape: 0
    }
  },
  mounted() {

    this.$socket.client.emit('join', 'player')




  },
  sockets: {
    initVideos(videos) {
      this.videos = videos
    },
    questionShow(data) {
      console.log(data)
      this.question = null
      this.video = null
      if(data.type === 'question') {
        this.question = data
        this.$forceUpdate()
        this.$nextTick(() => {
          document.querySelector('.question').innerHTML = data.question
          this.questionShow()
        })
      }
      else if(data.type === 'video') {
        console.log('video')
        this.video = data.name
        document.getElementById(data.name).currentTime = 0
        document.getElementById(data.name).play()
        this.$nextTick(() => {
          
          // anime({
          //   targets: 'video:not(#' + data.name + ')',
          //   opacity: [1, 0],
          //   duration: 0
          // })
          anime({
            targets: '#' + data.name,
            opacity: [0, 1]
          })
        })
      }
    },
    setEtape(etape) {
      this.etape = etape
    }
  },
  methods: {
    questionShow() {
      
      if(!this.question)
        return

      // Wrap every letter in a span
      var textWrapper = document.querySelector('.question');
      textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

      let responses_height = document.querySelector('.reponses').offsetHeight;
      console.log(responses_height)
      
      var tl = anime.timeline({
        easing: 'easeOutExpo',
        duration: 750
      });


// Add children
      tl
          .add({
            targets: '.question_nb',
            scale: [0, 1],
            opacity: [0, 1],
            translateY: ['20px', 0],
            easing: 'cubicBezier(1,.01,.67,1.32)',
            duration: 600,
          })
          .add({
            targets: '.question_nb',
            opacity: [1, 0],
            duration: 500,
            delay: 2000
          })
          .add({
            targets: '.question .letter',
            opacity: [0,1],
            translateZ: 0,
            easing: "easeOutExpo",
            duration: 950,
            delay: (el, i) => 20*i
          })
          .add({
            targets: '.question',
            translateY: [responses_height / 2, 0],
            delay: 1500,
          })
          .add({
            targets: '.reponses .reponse',
            scale: [.4,1],
            opacity: [0,1],
            translateY: ['10px', 0],
            // rotateX: [ '-40deg', 0 ],
            translateZ: 0,
            easing: 'cubicBezier(1,.01,.85,1.23)',
            duration: 300,
            delay: (el, i) => 2000*i
          })
    }
  }

}

</script>
<style>

.question_bloc .question .letter {
  /*display: inline-block;*/
  /*line-height: 1em;*/
}
</style>
<style scoped>
  @font-face {
    font-family: 'Adumu';
    src:url('../assets/fonts/Adumu.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  .player { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: #111; display: flex; align-items: center; justify-content: center; }
  
  .question_bloc { text-align: center; color: #fff; font-size: 48px; font-family: "Adumu"; font-weight: 400; text-transform: uppercase; padding: 0px 150px 0 150px;
    background: url(../assets/img/question_fond.jpg); width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;
  }
  .question_bloc .int { position: relative; top: -72px; width: 100% }
  .question_bloc .question_nb { font-size: 168px; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center;
    transform-origin: center 60%;
  }
  .question_bloc .question { font-size: 66px; line-height: 78px }

  .question_bloc .reponse:nth-child(1) { margin-top: 70px; }
  /*.question_bloc .reponses { perspective: 500px }*/
  .question_bloc .reponse { font-size: 68px; margin-bottom: 26px;  }
  .question_bloc .reponse:last-child { margin-bottom: 0; }
  
  .etape { position: absolute; bottom: -36px; left: 0; width: 56%; }
  
  video { position: fixed; top: 0; right: 0; bottom: 0; left: 0; transition: all .2s }
  
  .hide { opacity: 0; }
  
</style>