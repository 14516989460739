<template>
  <div class="formEdit">
    <form action="" @submit.prevent="">
      <label>Nom du fichier vidéo</label>
      <input type="text" placeholder="1" v-model="video.name">
      
      <div class="bouton" @click="$emit('save', video)">Enregistrer</div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'formEditVideo',
  props: {
    video: String
  },
  created() {
    if(!this.video) {
      this.video = {
        type: 'video',
        name: null,
      }
    }
  },
  methods: {
    
  }
}
</script>

<style scoped>


.formEdit { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: #fff; padding: 20px;
  box-shadow: 0 0 1px 0 rgb(18 32 73 / 8%), 0 8px 32px 0 rgb(55 92 192 / 8%); border: 1px solid #cce2fa; width: 400px;
}

input[type=text]::placeholder { opacity: .6; }

.bouton { justify-content: center; margin-top: 6px; }

.select_correct { padding: 0 0 0 16px; display: flex; align-items: center; margin-bottom: 6px; }
.select_correct input[type=checkbox] { width: 30px; height: 30px; margin-right: 16px; flex: 0 0 auto }
.select_correct input[type=text] { margin: 0; width: 100%; }
.select_correct[data-correct] input[type=text] { border-color: #79d634 }
</style>
